<template>
  <v-app>
    <div>
      <BreadcrumbComponent :items=items></BreadcrumbComponent>


      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="600px" @click:outside="close"> >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="add_user_btn" v-bind="attrs" v-on="on" style="margin-top:18px!important">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ form_title }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row style="margin-top:0px">
                  <v-col cols="12" sm="12" md="12">
                    <input label="Nom *" required v-model=user.full_name v-on:keyup="validateName()" placeholder="Nom *"
                      class="form-control"
                      :style="validate_name ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                    <small v-if="!validate_name" style="color:#ff7370;font-style:italic">{{ validate_name_error_message
                      }}</small>
                  </v-col>
                  <v-col cols="12" sm=6 md=6>
                    <input label="Email *" required v-model=user.email class="form-control" v-on:keyup="validateEmail()"
                      placeholder="E-mail *"
                      :style="validate_email ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                    <small v-if="!validate_email" style="color:#ff7370;font-style:italic">{{
                      validate_email_error_message
                      }}</small>
                  </v-col>
                  <v-col cols="12" sm=6 md=6>
                    <input label="Téléphone *" class="form-control" placeholder="Téléphone" v-model=user.phone>
                  </v-col>
                  <v-col cols="12" sm=6 md=6>
                    <input placeholder="Nom d'Utilisateur *" required v-on:keyup="validateUsername()"
                      class="form-control" v-model=user.username
                      :style="validate_username ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                    <small v-if="!validate_username" style="color:#ff7370;font-style:italic">{{
                      validate_username_error_message
                      }}</small>
                  </v-col>
                  <v-col cols="12" sm=6 md=6>
                    <input placeholder="Pin *" type="password" required class="form-control"
                      v-model=user.password v-on:keyup="validatePassword()"
                      :style="validate_password ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                    <small v-if="!validate_password" style="color:#ff7370;font-style:italic">{{
                      validate_password_error_message
                      }}</small>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <select v-model=user.role_id class="form-control">
                      <option value="" selected>-- Role --</option>
                      <option v-for="role in roles" v-bind:key="role.id" v-bind:value="role.id">{{ role.name }}</option>
                    </select>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <select v-model=user.status class="form-control">
                      <option value="" selected>-- Statut --</option>
                      <option v-for="stat in status" v-bind:key="stat.id" v-bind:value="stat.id">{{ stat.name }}</option>
                    </select>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <input placeholder="Commission" required v-model=user.commission class="form-control" type="number" v-on:keyup="validateCommission()">
                    <small v-if="!validate_commission" style="color:#ff7370;font-style:italic">{{ validate_commission_error_message }}</small>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <select v-model=user.rate_id class="form-control">
                      <option value="" selected>-- Devise de Vente --</option>
                      <option v-for="rate in rateinfo" v-bind:key="rate.id" v-bind:value="rate.id">{{ rate.name }}
                      </option>
                    </select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <select placeholder="Limite de Rabais" required v-model=user.discount_limit class="form-control">
                      <option value="0" selected>0 %</option>
                      <option v-for="discount in discounts" v-bind:key="discount" v-bind:value="discount">
                        {{ discount }} %
                      </option>
                    </select>
                    <small v-if="!validate_discount_limit" style="color:#ff7370;font-style:italic">{{ validate_discount_limit_error_message }}</small>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="close_modal_button" text @click="close">
                Fermer
              </v-btn>
              <v-btn color="blue" text class="confirm_modal_button" v-on:click=save :loading="loading"
                :disabled="loading" @click="loader = 'loading'">
                Valider
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <PreloaderComponent :enable=enable></PreloaderComponent>

      <v-container fluid class="settingscontainer" v-if=enable>
        <v-card elevation="2" class="text-right">
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" single-line hide-details
                class="tablesearch"></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="rows" :items-per-page="10" class="elevation-1" :search="search">
              <template v-slot:item.status="{ item }">
                <v-chip :class="getStatusColor(item.status)">
                  {{ getStatusLabel(item.status) }}
                </v-chip>
              </template>
              <template v-slot:item.rate_id="{ item }">
                {{ getRateLabel(item.rate_id) }}
              </template>
              <template v-slot:item.role.name="{ item }">
                <v-chip class=uppercase>
                  {{ getRoleLabel(item) }}
                </v-chip>
              </template>
              <template v-slot:item.discount_limit="{ item }">
                {{ item.discount_limit }} %
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 edit_button" @click="edit(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card>

        </v-card>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'UsersView',
  methods: {
    load: function () {
      var self = this;
      this.$axios.get('/users?include=role&orderasc=full_name')
        .then(function (response) {
          self.rows = response.data
          self.enable = true
        })
    },
    getRateLabel: function (rate) {
      if (rate == 0) {
        return 'HTG / USD'
      }

      if (rate == 1) {
        return 'HTG'
      }

      if (rate == 2) {
        return 'USD'
      }

    },
    validateName: function () {
      if (this.user.full_name == '') {
        this.validate_name = false
        this.validate_name_error_message = "Le nom ne peut pas être vide."
      } else {
        this.validate_name = true
      }
    },
    validateUsername: function () {
      if (this.user.username == '') {
        this.validate_username = false
        this.validate_username_error_message = "Le nom ne peut pas être vide."
      } else {
        this.validate_username = true
      }
    },
    validatePassword: function () {
      if (this.user.password == '') {
        this.validate_password = false
        this.validate_password_error_message = "Le mot de passe ne peut pas être vide."
        return
      } else {
        this.validate_password = true
      }

      if (this.user.password.length < 6) {
        this.validate_password = false
        this.validate_password_error_message = "6 caractères au minimum."
        return
      } else {
        this.validate_password = true
      }

      if (isNaN(parseInt(this.user.password))){
        this.validate_password = false
        this.validate_password_error_message = "Le mot de passe doit contenir que des chiffres."
        return
      } else {
        this.validate_password = true
      }
    },
    validateCommission: function () {
      if (this.user.commission == '') {
        this.validate_commission = false
        this.validate_commission_error_message = "La commission ne peut pas être vide."
      } else {
        this.validate_commission = true
      }
    },
    validateDiscountLimit: function () {
      if (this.user.discount_limit == '') {
        this.validate_discount_limit = false
        this.validate_discount_limit_error_message = "La limite de rabais ne peut pas être vide."
      } else {
        this.validate_discount_limit = true
      }
    },
    validateEmail: function () {
      if (this.user.email == '') {
        this.validate_email = false
        this.validate_email_error_message = "Vérifiez le format de l'e-mail."
      } else {
        this.validate_email = true
      }
    },
    getStatusColor: function (status) {
      var color = "class_red"
      if (status) {
        color = "class_green"
      }
      return color
    },
    getStatusLabel: function (status) {
      var label = "Inactif"
      if (status) {
        label = "Actif"
      }
      return label
    },
    getRoleLabel: function (item) {
      var label = "ADMIN"
      for (var i = this.roles.length - 1; i >= 0; i--) {
        if (this.roles[i].id == item.role_id) {
          label = this.roles[i].name
        }
      }
      return label
    },
    save: function () {
      var self = this
      if (this.validate_password && this.validate_username && this.validate_name && this.validate_email && this.user.full_name != '' && this.user.email != '' && this.user.username != '' && this.user.password != '') {
        this.loading = true
        var url = ''
        if (this.index > -1) {
          url = '/users/' + this.user.id
          var user = this.user
          delete user.role
          var config = {
            method: 'put',
            url: url + '?include=role&data=' + encodeURIComponent(JSON.stringify(user)),
          };
          this.$axios(config)
            .then(response => {
              if (response.status < 200 || response.status > 299) {
                self.loading = false
                alert("Une erreur s'est produite. Veuillez réessayer.")
                return
              }
              Object.assign(self.rows[self.index], response.data)
              self.dialog = false
              self.loading = false
              self.reset()
            }).catch(function (error) {
              console.log(error)
            })
        } else {
          url = '/users'
          this.$axios.post(url, self.user)
            .then(response => {
              if (response.status < 200 || response.status > 299) {
                self.loading = false
                alert("Une erreur s'est produite. Veuillez réessayer.")
                return
              }
              self.rows.push(response.data);
              self.dialog = false
              self.loading = false
              self.reset()
            })
        }
      } else {
        alert("Vérifiez les données du formulaire.")
      }

    },
    edit: function (item) {
      this.index = this.rows.indexOf(item)
      this.user = Object.assign({}, item)
      this.form_title = "Editer Utilisateur : " + this.user.full_name
      this.dialog = true
    },
    getRoles: function () {
      var self = this;
      this.$axios.get('/roles')
        .then(function (response) {
          self.roles = response.data
        })
    },
    close: function () {
      this.dialog = false
      this.reset()
      this.validate_name = true
      this.validate_email = true
      this.validate_username = true
      this.validate_password = true
      this.validate_commission = true
      this.validate_discount_limit = true
      this.loading = false
    },
    reset: function () {
      this.user = {
        username: '',
        password: '',
        full_name: '',
        commission: 0,
        rate_id: 1,
        phone: '',
        email: '',
        business_id: 0,
        role_id: '',
        discount_limit: 0,
        status: '',
      }
      this.index = -1
      this.form_title = 'Nouvel Utilisateur'
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.reset()
      })
    },
  },
  mounted() {
    this.load()
    this.getRoles()
  },
  data: () => ({
    search: '',
    status: [{ name: "Actif", id: true }, { name: 'Inactif', id: false }],
    rateinfo: [{ name: "HTG / USD", id: 0 }, { name: 'HTG', id: 1 }, { name: 'USD', id: 2 }],
    index: -1,
    loading: false,
    form_title: "Nouvel Utilisateur",
    items: [
      {
        text: 'Utilisateurs',
        disabled: false,
        href: '/users',
      }
    ],
    fluid: true,
    enable: false,
    headers: [
      {
        text: 'NOM',
        align: 'start',
        sortable: false,
        value: 'full_name',
      },
      { text: 'NOM D\'UTILISATEUR', value: 'username' },
      { text: 'TELEPHONE', value: 'phone' },
      { text: 'EMAIL', value: 'email' },
      { text: 'ROLE', value: 'role.name' },
      { text: 'STATUT', value: 'status' },
      { text: 'DEVISE DE VENTE', value: 'rate_id' },
      { text: 'LIMITE RABAIS', value: 'discount_limit' },
      { text: 'ACTIONS', value: 'actions' },
    ],
    rows: [],
    validate_name: true,
    validate_name_error_message: 'Le nom ne peut pas être vide.',
    validate_email: true,
    validate_username: true,
    validate_password: true,
    validate_commission: true,
    validate_discount_limit: true,
    validate_password_error_message: 'Le mot de passe ne peux pas être vide.',
    validate_username_error_message: 'Le nom d\'utilisateur ne peux pas être vide.',
    validate_phone_error_message: 'Vérifiez le format du téléphone.',
    validate_email_error_message: 'Le nom ne peut pas être vide.',
    validate_commission_error_message: 'La commission ne peut pas être vide.',
    validate_discount_limit_error_message: "La limite de rabais ne peut pas être vide.",
    roles: [],
    dialog: '',
    dialogDelete: false,
    user: {
      username: '',
      password: '',
      full_name: '',
      commission: 0,
      rate_id: 1,
      phone: '',
      email: '',
      business_id: 0,
      role_id: '',
      discount_limit: 0,
      status: '',
    },
    discounts : [ 
      5, 10, 15, 20, 25, 30
    ]
  }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
.text-right {
  text-align: right;
}

.v-application--wrap .theme--dark.v-btn.v-btn--has-bg {
  background-color: #F68D3A !important;
}

.settingscontainer {
  margin-top: 10px !important;
}

.v-application a {
  color: black !important;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

th.text-start,
td.text-start {
  text-align: center !important;
}

th.text-start:first-child,
td.text-start:first-child {
  text-align: left !important;
}

th.text-start:last-child,
td.text-start:last-child {
  text-align: right !important;
}

.class_green {
  background: #4caf50 !important;
  color: white !important;
}

.class_red,
.confirm_delete_button {
  background: #ff5252 !important;
  color: white !important;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.tablesearch .v-input__slot:before {
  display: none;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

.edit_button {
  font-size: 16px;
  padding: 5px;
  background: #003F74 !important;
  color: white !important;
  border-radius: 4px;
}

.delete_button {
  background: #ff5252 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
}

.v-application .justify-center {
  justify-content: right !important;
}

.v-application .v-btn {
  width: 70px !important;
  margin-right: 22px;
}

.v-dialog .v-card__title {
  background: #f3f3f3;
  border-bottom: 1px solid #ddd;
}

.v-btn.close_modal_button {
  background-color: #1976d2 !important;
  color: white !important;
}

.v-btn.confirm_modal_button {
  background-color: #4caf50 !important;
  color: white !important;
}

.v-dialog .v-card__actions {
  padding-bottom: 15px !important;
  padding-top: 20px !important;
  background: #f3f3f3;
  border-top: 1px solid #ddd;
}

.color-red {
  color: red !important;
}

.has_errors {
  padding-top: 0px;
  margin-top: 0px;
}

.add_user_btn {
  margin-top: 18px;
}

.v-card__actions .v-progress-circular {
  margin-top: 1px !important;
}

.v-card__actions .v-progress-circular circle {
  color: white !important;
}
</style>
